
<template>
	<div>
		<v-progress-linear :indeterminate="true" v-if="loading"></v-progress-linear>
		<v-container v-else class="my-2">
			<form @submit.prevent="updateOrderItems" autocomplete="off">
				<v-layout row wrap>
					<!-- {{order_items}} -->
					<v-flex xs12 lg12 xl12 md12 sm12 class="my-4">
						<div v-if="order_items.order_item_purchase_date!=null">
							Purchasing Date: {{ new Date(order_items.order_item_purchase_date).toISOString().split('T')[0]}} {{ new Date(order_items.order_item_purchase_date).toISOString().split('T')[1].split('.')[0]}}
						</div>

					</v-flex>
					<v-flex xs12 lg3 xl3 md3 sm4>
						<!-- order_item_date -->
						<v-text-field v-model="order_items.order_item_date" type="datetime-local" :label="$store.getters.language.data.order_items.order_item_date" class="mx-1" filled outlined dense required disabled>
						</v-text-field>

					</v-flex>
					<v-flex xs12 lg3 xl3 md3 sm4>
						<v-text-field v-model="order_items.order_sku_code" type="text" :label="$store.getters.language.data.order_items.order_sku_code" class="mx-1" filled outlined dense required>
						</v-text-field>
					</v-flex>

					<v-flex xs12 lg3 xl3 md3 sm4>
						<v-text-field v-model="order_items.order_item_price" type="text" :label="$store.getters.language.data.order_items.order_item_price" class="mx-1" filled outlined dense required>
						</v-text-field>
					</v-flex>
					<v-flex xs12 lg3 xl3 md3 sm4 v-if="user.user_role == 1">
						<v-select class="mx-1" clearable :items="['pending','purchased','packed','soldout','delivered','incomplete','soldout_told']" v-model="order_items.order_item_status" dense filled outlined item-text="order_item_status" item-value="order_item_status" :return-object="false" :label="$store.getters.language.data.order_items.order_item_status">
						</v-select>
					</v-flex>
					<v-flex xs12 lg3 xl3 md3 sm4 v-if="user.user_role != 1">
						<v-select class="mx-1" clearable :items="['pending','incomplete']" v-model="order_items.order_item_status" dense filled outlined item-text="order_item_status" item-value="order_item_status" :return-object="false" :label="$store.getters.language.data.order_items.order_item_status">
						</v-select>
					</v-flex>

					<v-flex xs12 lg3 xl3 md3 sm4>
						<v-text-field v-model="order_items.order_item_quantity" type="number" :label="$store.getters.language.data.order_items.order_item_quantity" class="mx-1" filled outlined dense required>
						</v-text-field>
					</v-flex>
					<v-flex xs12 lg3 xl3 md3 sm4>
						<v-text-field v-model="order_items.order_item_note" type="textarea" :label="$store.getters.language.data.order_items.order_item_note" class="mx-1" filled outlined dense>
						</v-text-field>
					</v-flex>
					<v-flex xs12 lg3 xl3 md3 sm4>
						<v-text-field v-model="order_items.order_item_link" type="text" :label="$store.getters.language.data.order_items.order_item_link" class="mx-1" filled outlined dense>
						</v-text-field>
					</v-flex>
					<v-flex xs12 lg3 xl3 md3 sm4>
						<v-select class="mx-1" clearable :items="orders" v-model="order_items.order_id" dense filled outlined item-text="order_id" item-value="order_id" :return-object="false" :label="$store.getters.language.data.orders.order_id" disabled>
						</v-select>
					</v-flex>
					<v-flex xs12 lg3 xl3 md3 sm4>
						<v-select class="mx-1" clearable :items="users" v-model="order_items.user_id" dense filled outlined item-text="user_username" item-value="user_id" :return-object="false" :label="$store.getters.language.data.users.user_username" disabled>
						</v-select>
					</v-flex>

					<v-flex xs12 lg2 xl2 md2 sm4>
						<v-btn color="success" type="submit">{{$store.getters.language.data.order_items.update_btn}}</v-btn>
					</v-flex>

				</v-layout>
			</form>
		</v-container>
		<v-snackbar v-model="snackbar.value" :color="snackbar.color">
			{{snackbar.text}}
		</v-snackbar>
	</div>
</template>
<script>
	import requests from './../../requests/order_items.request.js'
	export default {
		data() {
			return {
				order_items: {},
				id: '',
				loading: true,
				snackbar: {
					value: false,
					text: '',
					color: ''
				},
			}
		},
		computed: {

			orders() {
				return this.$store.getters.orders_list
			},

			users() {
				return this.$store.getters.users_list
			},

			user() {
				return this.$store.getters.user
			},

		},
		mounted() {
			this.id = this.$route.params.id
			this.getOneOrderItems()
		},
		methods: {
			getOneOrderItems() {
				this.loading = true
				requests.getOneOrderItems(this.id).then(r => {
					if (r.status == 200) {
						this.order_items = r.data.row
						this.order_items.order_item_date = this.order_items.order_item_date.split('.')[0]
						this.loading = false
					} else {
						this.snackbar = {
							value: true,
							text: 'Fail to read OrderItems',
							color: 'error'
						}
					}
				})
					.catch(e => {
						this.snackbar = {
							value: true,
							text: 'Fail to read OrderItems',
							color: 'error'
						}
					})
					.finally(() => {
						this.loading = false
					})
			},
			updateOrderItems() {
				this.loading = true
				delete this.order_items.order_item_date
				this.order_items.user_id = this.user.user_id
				requests.updateOrderItems(this.id, this.order_items).then(r => {
					if (r.status == 200) {
						this.snackbar = {
							value: true,
							text: 'OrderItems Updated',
							color: 'success'
						}
						this.loading = false
					} else {
						this.snackbar = {
							value: true,
							text: 'Update Faild',
							color: 'error'
						}
						this.loading = false
					}
				})
					.catch(e => {
						this.snackbar = {
							value: true,
							text: 'Update Faild',
							color: 'error'
						}
						this.loading = false
					})
					.finally(() => {
						this.loading = false
					})
			},
		},
	}
</script>
                    