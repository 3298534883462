
import axios from 'axios';
export default {
    
    async createOrderItems(params)  {
        return await axios.post(`order_items/create` , params)
    },
    async createOrderItemsList(params)  {
        return await axios.post(`order_items/create/list` , params)
    },
    async updateOrderItemsColumn(column , value , data)  {
        return await axios.put(`order_items/update_list?${column}=${value}` , data)
    },
    async deleteOrderItemsList(list)  {
        return await axios.delete(`order_items/delete_list` , {
            data: {
                list:list
            }
        })
    },
    async updateOrderItemsByIDsListStatus(list, status) {
        return await axios.put(`order_items/update_list_status`, {
            list: list,
            status: status
        })
    },
    async reportOrderItems(column , value)  {
        return await axios.get(`order_items/report?${column}=${value}`)
    },
    async searchOrderItems(params)  {
        return await axios.post(`order_items/search`,params)
    },
    async searchOrderItemsDetail(params)  {
        return await axios.post(`order_items/detailsearch`,params)
    },
    async getAllOrderItems()  {
        return await axios.get(`order_items/all`)
    },
    async getFastOrderItems()  {
        return await axios.get(`order_items/fast_search`)
    },
    async getOneOrderItems(order_item_id)  {
        return await axios.get(`order_items/all/${order_item_id}`)
    },
    async getOrderItemsByColumn(column , value)  {
        return await axios.get(`order_items/filter?column=${column}&value=${value}`)
    },
    async deleteOrderItems(order_item_id)  {
        return await axios.delete(`order_items/delete/${order_item_id}`)
    },
    async updateOrderItems(order_item_id , params)  {
        return await axios.put(`order_items/update/${order_item_id}` , params)
    },
    async updateStatus(order_id)  {
        return await axios.put(`order_items/update_status/${order_id}`)
    }
}